/* montserrat-alternates-300 - latin */
@font-face {
    font-family: 'Montserrat Alternates';
    font-style: normal;
    font-weight: 300;
    src: url('../webfonts/montserrat-alternates-v12-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/montserrat-alternates-v12-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/montserrat-alternates-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/montserrat-alternates-v12-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/montserrat-alternates-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/montserrat-alternates-v12-latin-300.svg#MontserratAlternates') format('svg'); /* Legacy iOS */
}

/* montserrat-alternates-regular - latin */
@font-face {
    font-family: 'Montserrat Alternates';
    font-style: normal;
    font-weight: 400;
    src: url('../webfonts/montserrat-alternates-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/montserrat-alternates-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/montserrat-alternates-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/montserrat-alternates-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/montserrat-alternates-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/montserrat-alternates-v12-latin-regular.svg#MontserratAlternates') format('svg'); /* Legacy iOS */
}



:root {
    --navbar-height: 2rem;
    --accent-color: rgba(255, 233, 107, 0.72);
}

a:focus {
    box-shadow: none;
}
body, html {
    height: 100%;
    display: flex;
    flex-direction: column;
}

main {
    flex-grow: 1;
}

header {
    margin-bottom: 1rem;
}

.container.fixed-width {
    margin: auto;
    max-width: 960px;
}

.navbar-section a,
.navbar-section span {
    position: relative;
    height: var(--navbar-height);
    font-size: 1.1rem;
    padding: 10px .4rem 1px .4rem;
    color: #3e3e3e;
    text-decoration: none;
}


.navbar-section a:active:after,
.navbar-section a:hover:after {
    content: "";
    margin-top: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 8px;
    background: linear-gradient(
            100deg,
            rgba(255, 221, 64, 0) 0.9%,
            rgba(255, 221, 64, 1) 2.4%,
            rgba(255, 221, 64, 0.5) 5.8%,
            rgba(255, 221, 64, 0.1) 93%,
            rgba(255, 221, 64, 0.7) 96%,
            rgba(255, 221, 64, 0) 98%
    ),
    linear-gradient(
            180deg,
            rgba(255, 221, 64, 0) 0%,
            rgba(255, 221, 64, 0.3) 7.9%,
            rgba(255, 221, 64, 0) 15%
    );
    border-radius: 0.125em;
    box-decoration-break: clone;
    font-style: normal;
    padding: 0.125em 0.25em;
}

.navbar-section a + a,
.navbar-section span + a {
    margin-left: .25rem;
}

.navbar-section a.active:after,
.navbar-section span.active:after {
    content: "";
    margin-top: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 8px;
    background: linear-gradient(
            100deg,
            rgba(255, 221, 64, 0) 0.9%,
            rgba(255, 221, 64, 1) 2.4%,
            rgba(255, 221, 64, 0.5) 5.8%,
            rgba(255, 221, 64, 0.1) 93%,
            rgba(255, 221, 64, 0.7) 96%,
            rgba(255, 221, 64, 0) 98%
    ),
    linear-gradient(
            180deg,
            rgba(255, 221, 64, 0) 0%,
            rgba(255, 221, 64, 0.3) 7.9%,
            rgba(255, 221, 64, 0) 15%
    );
    border-radius: 0.125em;
    box-decoration-break: clone;
    font-style: normal;
    padding: 0.125em 0.25em;
}

.navbar {
    font-family: "Montserrat Alternates", sans-serif;
    padding: 0.5rem;
}

.centered-vertical {
    align-items: center;
    display: flex;
}

.intro {
    align-items: center;
    display: flex;
    justify-content: center;
    font-family: "Montserrat Alternates", sans-serif;
}

.accent-border-bottom {
    position: relative;
    margin-bottom: 10px;
}

.accent-border-bottom:after {
    content: "";
    margin-top: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 8px;
    background: linear-gradient(
            100deg,
            rgba(255, 221, 64, 0) 0.9%,
            rgba(255, 221, 64, 1) 2.4%,
            rgba(255, 221, 64, 0.5) 5.8%,
            rgba(255, 221, 64, 0.1) 93%,
            rgba(255, 221, 64, 0.7) 96%,
            rgba(255, 221, 64, 0) 98%
    ),
    linear-gradient(
            180deg,
            rgba(255, 221, 64, 0) 0%,
            rgba(255, 221, 64, 0.3) 7.9%,
            rgba(255, 221, 64, 0) 15%
    );
    border-radius: 0.125em;
    box-decoration-break: clone;
    font-style: normal;
    padding: 0.125em 0.25em;
}

.cv-techstack {
    margin: 0.5rem 1rem;
    color: #101010;
    font-size: 0.7rem;
    font-weight: lighter;
}

.cv-techstack ul {
    margin: 0 1rem;
    line-height: 0.8rem;
}

